import React, { FC, ReactElement } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import TourSplash from '../components/tour/tour-splash'
import Container from '../components/container'
import { EventType } from '../types'

export const query = graphql`
  {
    db {
      upcoming_events(
        limit: 100
        where: { band: { name: { _eq: "Star Kitchen" } } }
        order_by: [{ date: asc }]
      ) {
        id
        date
        name
        website
        venue {
          name
          city
          state
          country
          website
        }
        set_time
        set_length
        facebook_event
        event_tickets {
          id
          link
          label
        }
        headliner_band {
          name
        }
        is_festival
        start_date
        end_date
        canceled
        canceled_notes
        notes
      }
    }
  }
`

interface TourPageProps {
  data?: {
    db?: {
      upcoming_events?: [EventType]
    }
  }
}

const TourPage: FC<TourPageProps> = ({ data }: TourPageProps): ReactElement => (
  <Layout
    title="Star Kitchen Tour - Upcoming Events"
    canonical="/tour/"
    currentNav="Tour"
  >
    <Container>
      <TourSplash data={data} links="internal" />
    </Container>
  </Layout>
)

export default TourPage
